import React from 'react'
import { useEffect, useState } from 'react'

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from 'swiper'

// Import React Icons
import {IoMdArrowDropleft, IoMdArrowDropright} from 'react-icons/io'

// Import Swiper styles
import "swiper/css";

// Lightgallery
import LightGallery from 'lightgallery/react';

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

SwiperCore.use([Autoplay])
SwiperCore.use([Navigation])

const PhotoGallery = ({ data }) => {
  const [isMobile, setIsMobile] = useState(false)

  //choose the screen size
  const handleResize = () => {
      if(window.innerWidth < 992){
          setIsMobile(true)
      }else{
          setIsMobile(false)
      }
  }

  //create an event listener
  useEffect(() => {
      handleResize()
      window.addEventListener("resize", handleResize)
  }, [])

  const params = {
      pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
          noSwiping: false,
      },
      slidesPerView: 7,
      spaceBetween: 10,
      loop: (data.length > 6)? true : false,
      navigation: {
          prevEl: '.gallery-wrapper .swiper-button-prev',
          nextEl: '.gallery-wrapper .swiper-button-next'
      }
  }

  const clickDatalayer = (idx) => {
    var dl = {
      'event': 'gallery_post_clicked',
      'outbound_link': '',
      'tile_position': (idx + 1)
    }
    //Data layer - Gallery Post Clicked event
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(dl)
  }

  return (
    <div className='gallery-wrapper'>
      {(isMobile)?
          <div className='d-flex flex-wrap'>
            {data.map((item, idx) => {
              return(
                <div
                  className='gallery-item' 
                  key={'photo-gallery-item-' + idx} 
                  style={{backgroundImage: `url(${item.gallery_photo.url})`}}
                  role="button"
                  tabIndex={idx}
                  onClick={() => {
                    //open currently selected item
                    var curGalleryImg = document.getElementById("gallery-image-" + idx)
                    curGalleryImg.click()
                    clickDatalayer(idx)
                  }}
                  onKeyDown={() => {
                    //open currently selected item
                    var curGalleryImg = document.getElementById("gallery-image-" + idx)
                    curGalleryImg.click()
                    clickDatalayer(idx)
                  }}
                >
                </div>
              )
            })}
          </div>
      :
        <>
          {/* Photo Gallery Carousel */}
          <Swiper className={(data.length > 6)? 'photo-gallery-items photo-gallery-carousel' : 'photo-gallery-items photo-gallery-carousel small-gallery'} {...params}>
              {data.map((item, idx) => {
                return(
                    <SwiperSlide 
                      className='photo-gallery-item'  
                      key={'photo-gallery-item-' +  idx} 
                      onClick={() => {
                        //open currently selected item
                        var curGalleryImg = document.getElementById("gallery-image-" + idx)
                        curGalleryImg.click()

                        clickDatalayer(idx)
                      }}
                      onKeyDown={() => {
                        //open currently selected item
                        var curGalleryImg = document.getElementById("gallery-image-" + idx)
                        curGalleryImg.click()

                        clickDatalayer(idx)
                      }}
                    >
                        <PhotoItem item={item.gallery_photo} idx={idx} />
                    </SwiperSlide>
                )
              })}
          </Swiper>

          {/* Swiper Button */}
          {(data.length > 6)?
            <div>
                <div className='swiper-button-prev'>
                    <IoMdArrowDropleft />
                </div>
                <div className='swiper-button-next'>
                    <IoMdArrowDropright />
                </div>
            </div>
            : null
          }
        </>
      }
      <HiddenLightGallery items={data} />
    </div>
  )
}

const PhotoItem = ({item, idx}) => {
  return(
    <div className="photo-gallery-img-wrapper">
      <img src={item.url} alt={(item.alt)? item.alt : 'photo gallery-' + idx} />
    </div>
  )
}

const HiddenLightGallery = ({items}) => {
  return(
    <LightGallery
      onInit={() => {
      }}
      download={false}
      share={false}
      speed={500}
    >
      {items.map((item, idx) => {
        return(
          <a href={item.gallery_photo.url} id={"gallery-image-" + idx} key={"gallery-image-" + idx}>
            {/* <img src={item.gallery_photo.url} alt={(item.gallery_photo.alt)? item.gallery_photo.alt : 'Photo Gallery'} width="10px"/> */}
          </a>
        )
      })}
    </LightGallery>
  )
}

export default PhotoGallery